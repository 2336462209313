@import "/src/constants";

#project-short-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($dark-chocolate, 0.95);
  max-width: unset;
  border-top: 7px solid $green;
  border-bottom: 7px solid $green;
  margin: 256px auto;

  h1 {
    font-family: "Permanent Marker", cursive;
    color: $beige;
    margin: 0 auto;
    text-align: center;
    padding: 128px 32px;
    font-size: 72px;
    max-width: 1200px;
  }
}

@media (max-width: 900px) {
  #project-short-section {
    h1 {
      font-size: 56px;
    }
  }
}

@media (max-width: 600px) {
  #project-short-section {
    margin: 128px auto;

    h1 {
      font-size: 40px;
      padding: 64px 16px;
    }
  }
}

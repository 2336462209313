#project-short-section {
  max-width: unset;
  background-color: #1e2019f2;
  border-top: 7px solid #469f38;
  border-bottom: 7px solid #469f38;
  justify-content: center;
  align-items: center;
  margin: 256px auto;
  display: flex;
}

#project-short-section h1 {
  color: #e2efb3;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 128px 32px;
  font-family: Permanent Marker, cursive;
  font-size: 72px;
}

@media (max-width: 900px) {
  #project-short-section h1 {
    font-size: 56px;
  }
}

@media (max-width: 600px) {
  #project-short-section {
    margin: 128px auto;
  }

  #project-short-section h1 {
    padding: 64px 16px;
    font-size: 40px;
  }
}

/*# sourceMappingURL=index.459b8257.css.map */
